import { AppBar, IconButton, makeStyles, Menu, MenuItem, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import MenuIcon from '@material-ui/icons/Menu';
import { AccountCircle } from "@material-ui/icons";
import { useContext } from "react";
import NavContext from "../../context/nav-context";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
// import { useHistory } from 'react-router-dom';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';

const drawerWidth = 250;

const useStyles = makeStyles(theme => ({
  appbar: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  appbarFull: {
    width: '100%'
  },
  toolbar: theme.mixins.toolbar,
  date: {
    flexGrow: 1
  },
  avatar: {
    marginLeft: theme.spacing(2)
  }
}));

const MyAppBar = ({isAuthenticated, onLogout, jwt, onUpdateSettings, settings, onFetchSettings}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navContext = useContext(NavContext);
  // const history = useHistory();

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(null);
  const profileMenuOpen = Boolean(profileAnchorEl);
  const handleProfileMenu = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileCloseMenu = () => {
    setProfileAnchorEl(null);
  };
  const handleLogout = () => {
    handleProfileCloseMenu();
    onLogout();
  }
  const toggleOrdering = () => {
    const callback = () => {
      onFetchSettings(jwt)
    }
    const data = {
      id: settings.id,
      ordering_enabled: settings?.ordering_enabled === "1" ? 0 : 1,
    }
    console.log(settings.ordering_enabled)
    console.log({data})
    onUpdateSettings(jwt, data, callback)
  }
  // const handleProfile = () => {
  //   handleProfileCloseMenu();
  //   history.push('/');
  // }

  return (
    isAuthenticated ?
    <AppBar
      className={`${isSmallScreen ? classes.appbarFull : classes.appbar}`}
      elevation={5}
    >
      <Toolbar>
        <Typography variant='h6' className={classes.date}>
          {navContext.pageName}
        </Typography>

        <div>
          {settings && 
          <Tooltip 
            arrow 
            title={settings?.ordering_enabled === "1" 
            ? <span> Poručivanje je <strong>AKTIVNO</strong></span>
            : <span> Poručivanje je <strong>NEAKTIVNO</strong></span>}
          >
            <IconButton
              aria-label="set ordering status"
              aria-controls="ordering-control"
              onClick={toggleOrdering}
              color="inherit"
              className={`
              scaleIn 
              fadeIn 
              ${settings?.ordering_enabled === "1" ? 'pressed' : ''}
              `}
            >
              {settings?.ordering_enabled === "1" 
              ? <PhoneEnabledIcon className='green' /> 
              : <PhoneDisabledIcon className='red'  />}
            </IconButton>
          </Tooltip>}

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleProfileMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={profileAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            getContentAnchorEl={null}
            keepMounted
            open={profileMenuOpen}
            onClose={handleProfileCloseMenu}
          >
            {/* <MenuItem onClick={handleProfile}>Profil</MenuItem> */}
            <MenuItem onClick={handleLogout}>Odjava</MenuItem>
          </Menu>
        </div>

        {isSmallScreen ?
          <IconButton
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={navContext.toggleExpanded}
          >
            <MenuIcon />
          </IconButton> : null}

      </Toolbar>
    </AppBar> : null
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.jwt !== null,
    jwt: state.auth.jwt,
    settings: state.settings.settings
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout()),
    onUpdateSettings: (jwt, data, callback) => dispatch(actions.updateSettings(jwt, data, callback)),
    onFetchSettings: (jwt, callback) => dispatch(actions.fetchSettings(jwt, callback)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAppBar);